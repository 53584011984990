body {
  font-family: 'Noto Sans Japanese';
}
.overlay-loading {
  position: fixed !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10000;
  transition: all 0.3s ease;
  display: flex;
  background: black;
  color: white;
  opacity: 0;
  pointer-events: none;
}
.overlay-loading.loading {
  opacity: 0.7;
  pointer-events: initial;
}
.container-small, .container-large {
    max-width: 100%;
}
@media (min-width: 768px) {
    .container-small {
        width: 300px;
    }
    .container-large {
        width: 970px;
    }
}
@media (min-width: 992px) {
    .container-small {
        width: 500px;
    }
    .container-large {
        width: 1170px;
    }
}
@media (min-width: 1200px) {
    .container-small {
        width: 700px;
    }
    .container-large {
        width: 1500px;
    }
}
td {
  vertical-align: middle！important;
}
.config {
  background-color: whitesmoke
}
.entity-config {
  background-color: lightgray
}
.key-config {
  background-color: silver
}
.vertical-center {
  align-items: center;
  display: flex;
}

.accordion {
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 2px;
    width: 800px;
    font-size: 1.2rem;
}

.accordion__item + .accordion__item {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
    background-color: #f4f4f4;
    color: #444;
    cursor: pointer;
    padding: 18px;
    width: 100%;
    text-align: left;
    border: none;
    font: bold;
}

.accordion__button:hover {
    background-color: #ddd;
}

.accordion__panel {
    padding: 20px;
    animation: fadein 0.35s ease-in;
    height: auto;
}

.require {
    color: red;
    font: bold;
}

table {
    table-layout: fixed;
    word-wrap: break-word;
    width: 100%;
}

table.file th {
    width: 400;
}

table.file td {
    width: 400;
}

table.destination th {
    width: 300;
}

table.destination td {
    width: 300;
}
.custom-ui {
    width: 500px;
    padding: 40px;
    background: hsl(147, 55%, 48%);
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
    color: #fff;
}

.custom-ui button {
    width: 160px;
    padding: 10px;
    border: 1px solid #fff;
    margin: 10px;
    cursor: pointer;
    background: none;
    color: #fff;
    font-size: 14px;
}
