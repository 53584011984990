.navbar-menu {
  height: 34px;
  margin: 0;
  padding: 0 16px;
  box-sizing: border-box;
  background: hsl(147, 55%, 48%);
}
.navbar-menu-item {
  line-height: 34px;
  color: white;
}
.navbar-menu-item {
  color: white;
}
.navbar-menu-select-item {
  color: black;
  display: block;
}

.navbar-menu-select-item:hover {
  color: white;
  background:  hsl(147, 55%, 48%);
}
